@tailwind base;
@tailwind components;
@tailwind utilities;

.animation-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.8);
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.glow::before,
.glow::after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 1.5rem;
  background: linear-gradient(45deg, #2084F9, #06b6d4, #2084F9, #06b6d4, #2084F9, #06b6d4);
  background-size: 400%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  animation: glow 30s linear infinite;
}

.glow::after {
  filter: blur(20px);
  opacity: 0.5;
}

.glow2::before,
.glow2::after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 1.5rem;
  background: linear-gradient(45deg, #F69C2D, #C55691, #F69C2D, #C55691, #F69C2D, #C55691);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glow 30s linear infinite;
}

.glow2::after {
  filter: blur(20px);
  opacity: 0.5;
}

.glow3::before,
.glow3::after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 0.75rem;
  background: linear-gradient(45deg, #2084F9, #06b6d4, #2084F9, #06b6d4, #2084F9, #06b6d4);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glow 25s linear infinite;
}

.glow3::after {
  filter: blur(10px);
  opacity: 0.5;
}



.glowTopBorder::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1.2px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;

  background: conic-gradient(from 0deg at 50% 50%, #2084F9 -7.19deg, transparent 14deg, transparent 51deg, transparent 311deg, #2084F9 353deg, transparent 374deg);
  background: conic-gradient(from 180deg at 50% 50%, transparent 0deg, transparent 145deg, #2084F9 165deg, #f142b6 175deg, #ffbc31 185deg, transparent 195deg, #61dafb 205deg, transparent 217deg, transparent 1turn);

}

.glowCardBorder::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1.2px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* background: conic-gradient(from -100deg at 50% 50%, #2084F9 -7.19deg, transparent 14deg, transparent 51deg, transparent 311deg, #2084F9 353deg, transparent 374deg); */
  background: conic-gradient(from 270deg  at 50% 50%,   #2084F9  90deg, transparent 150deg, #f142b6 180deg, transparent 270deg, #61dafb 350deg);

}

.glowBorderFeatureBox::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1.2px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* background: conic-gradient(from -100deg at 50% 50%, #2084F9 -7.19deg, transparent 14deg, transparent 51deg, transparent 311deg, #2084F9 353deg, transparent 374deg); */
  background: conic-gradient(from 200deg  at 50% 50%,   transparent 10deg, #2084F9  150deg, #f142b6 180deg, transparent 270deg, transparent 350deg);

}

.gradientButton {
  background-image: linear-gradient(96deg, var(--start-color), var(--end-color));
}

.carousel-container {
  @apply flex;
  @apply overflow-hidden;
  @apply space-x-4;
}

.items {
  @apply flex;
  @apply gap-4;
  width: 4000px;
}

.item {
  @apply rounded-lg;
  @apply shadow;
}

.cta-gradient {
  position: relative;
  /* isolation: isolate; */
  /* overflow: hidden; */
}


.cta-gradient span {
  position: relative;
  display: block;
  height: 100%;
}

.cta-gradient::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: inherit;
  background: linear-gradient(45deg, #2084F9, #06b6d4, #2084F9, #06b6d4, #2084F9, #06b6d4);
  background-size: 400%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  animation: glow 30s linear infinite;
}

@keyframes bgColor {
  to {
    transform: translateX(-25%)
  }
}



@keyframes glow {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


.btn-grad {
  background-image: linear-gradient(to right, #2084F9 0%, #06b6d4 51%, #2084F9 80%, #2084F9 100%);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 4px #000;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  color: #f7f7f7;
  text-decoration: none;
  box-shadow: 0 0 14px #2084F9;
}

.card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
}

.card::before,
.card-border {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms;
}

.card:hover::before {
  opacity: 1
}

#cards:hover>.card>.card-border {
  opacity: 1
}

.card::before {
  background: radial-gradient(800px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.06), transparent 40%);
  z-index: 3;
}

.card-border {
  background: radial-gradient(400px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.3), transparent 40%);
  z-index: 1;
}