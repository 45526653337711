/////////////////////////////////////////////////////////////////////////////////
// CUSTOM FONTS AND COLOURS
/////////////////////////////////////////////////////////////////////////////////
@font-face {
    font-family: sf_thin;
    src: url(./san_francisco/pro/SF-Pro-Display-Thin.otf) format('opentype');
}

@font-face {
    font-family: sf_light;
    src: url(./san_francisco/pro/SF-Pro-Display-Light.otf) format('opentype');
}

@font-face {
    font-family: sf_regular;
    src: url(./san_francisco/pro/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
    font-family: sf_semibold;
    src: url(./san_francisco/pro/SF-Pro-Display-Semibold.otf) format('opentype');
}

@font-face {
    font-family: sf_bold;
    src: url(./san_francisco/pro/SF-Pro-Display-Bold.otf) format('opentype');
}

@font-face {
    font-family: sf_black;
    src: url(./san_francisco/pro/SF-Pro-Display-Black.otf) format('opentype');
}

@font-face {
    font-family: roboto_thin;
    src: url(./roboto/Roboto-Thin.ttf) format('truetype');
}

@font-face {
    font-family: roboto_light;
    src: url(./roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: roboto_regular;
    src: url(./roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: roboto_bold;
    src: url(./roboto/Roboto-Bold.ttf) format('truetype');
}

:root {
    --primary_colour: rgba(0, 0, 0, 1.0);
    --secondary_colour: rgba(80, 80, 80, 1.0);
    --tertiary_colour: rgba(120, 120, 120, 1.0);
    --quaternary_colour: rgba(185, 185, 185, 1.0);
    --quinary_colour: rgba(255, 255, 255, 1.0);
    --navbar_height: 85px;
    --max_width: 1200px;
    --up_arrow_height: 50px;
    --up_arrow_width: 50px;
}

// export some variables so that they can be used inside .js files
:export {
    --primary_colour: rgba(0, 0, 0, 1.0);
    --secondary_colour: rgba(80, 80, 80, 1.0);
    --tertiary_colour: rgba(120, 120, 120, 1.0);
    --quaternary_colour: rgba(185, 185, 185, 1.0);
    --quinary_colour: rgba(255, 255, 255, 1.0);
    --navbar_height: 85px;
}

body{
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    height: 100%;
    width: 100%;
}

html {
    scroll-behaviour: smooth;
}

.app{
    height: 100%;
    width: 100%;
    padding-top: 0px;
    margin-top: 0px;
}

.app_navbar_desktop{
    width: 100%;
    max-height: var(--navbar_height);
}

.app_content_desktop{
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.button_container{
    height: var(--up_arrow_height);
    width: var(--up_arrow_width);

    position: fixed;
    right: -100px;
    bottom: 20px;
    transition: right 0.5s;
    cursor: pointer;
    font-size: 20px;
    padding: 15px;

    background: #FFFFFF;
    border: 1px solid var(--primary_colour);
    box-sizing: border-box;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;

    display: inline-block;
}

.vertical_align{
    vertical-align: middle;
    display: table-cell;
    height: 100%;
    z-index: -10;
    width: 100%;
}

.button_transition {
    right: 15px;
}

.up_arrow{
    margin: auto;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 100%;
}