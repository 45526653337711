.button {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_primary {
  color: white;
  background-color: #000;
}
.button_secondary {
  color: #333;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.button_small {
  font-size: 12px;
  padding: 10px 16px;
}
.button_medium {
  font-size: 14px;
  padding: 11px 24px;
}
.button_large {
  font-size: 16px;
  padding: 12px 32px;
}
