.desktop_root{
    width: 100%;
    height: calc(var(--navbar_height));
    display: flex;
    flex-direction: row;
    z-index: 20;
    margin-top: 0px;
    top: 0;
    background: transparent;
    position: fixed;
    //backdrop-filter: blur(20px);
    //-webkit-backdrop-filter: blur(20px);
    //background-color: rgba(255, 255, 255, 0.9);
}

.desktop_inner{
    height: var(--navbar_height);
    width: 100%;
    max-width: var(--max_width);
    margin: 0 auto !important;
    float: none !important; 
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 21;
    //border: 1px solid red;
}

.logo{
    width: 220px;
    height: 60px;
}

.logo:hover{
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    .logo{
        width: 175px;
        height: 50px;
    }
}

.desktop_sub_root{
    display: table-cell;
    width: 100%;
    height: var(--navbar_height);
    position: fixed;
    margin-top: -1.5px;
    top: 0;
    //background-color: var(--quinary_colour);
    z-index: 20;
    border-bottom: 1.5px solid var(--primary_colour);
    transition: background-color 0.15s ease;
}

.hamburguer_menu_icon_div{
    height: fit-content;
    width: fit-content;
    margin-left: 20px;
    //border: 1px solid red;
}

@media only screen and (min-width: 750px) {
    .hamburguer_menu_icon_div{
        display: none;
    }
}

.hamburguer_menu_icon{
    width: 20px;
    height: 20px;
    opacity: 0.75;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    //border: 1px solid red;
}

.hamburguer_menu_icon:hover{
    cursor: pointer;
    //opacity: 1.0;
    //transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_div{
    position: fixed;
    overflow: visible;
    z-index: 1000;
    width: 100%;
    height: var(--vh);
    min-height: -webkit-fill-available;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.hamburguer_menu_div_left{
    flex-basis: 235px;
    position: relative;
    z-index: 1000;
    height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 10px 0 30px -2px rgba(0, 0, 0, 0.25);
}

.hamburguer_menu_div_left_background{
    position: absolute;
    z-index: 20;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    opacity: 0.15;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //background-attachment: fixed;
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(249, 249, 249,1)), to(rgba(249,249,249,0)));
    mask-image: linear-gradient(to bottom, rgba(249, 249, 249,1), rgba(249,249,249,0));
    //background-attachment: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    transform:translatez(0);
    -webkit-transform:translatez(0);
}

.hamburguer_menu_div_right{
    flex: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.hamburguer_menu_div_left_inner{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.home_link_div{
    margin-top: 20px;
    height: fit-content;
    width: 100%;
    text-align: center;
}

.home_link{
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    font-family: sf_bold;
    font-size: 25px;
    opacity: 0.85;
    transition: opacity 0.25s ease-in-out;
    //color: var(--primary_colour);
    color: var(--primary_colour);
}

.home_link:hover{
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 0.25s ease-in-out;
}

.description{
    height: fit-content;
    width: fit-content;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    font-family: sf_regular;
    font-size: 11px;
    opacity: 0.75;
    color: var(--secondary_colour);
}

.hamburguer_menu_item_div{
    flex-basis: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
    //border: 1px solid white;
}

.hamburguer_menu_item_div:hover{
    cursor: pointer;
    background-color: rgba(211, 211, 211, 0.35);
}

.hamburguer_menu_item_icon_div{
    flex: 1;
    width: fit-content;
    //border: 1px solid red;
}

.hamburguer_menu_item_icon{
    height: auto;
    width: 15px;
    aspect-ratio: 0.4;
    opacity: 0.75;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    //border: 1px solid red;
}

.hamburguer_menu_item_text{
    flex: 5;
    padding-left: 10px;
    font-family: sf_regular;
    font-size: 12px;
    opacity: 0.65;
    color: var(--primary_colour);
    transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_item_div:hover .hamburguer_menu_item_text{
    opacity: 1.0;
    transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_bottom_div{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    z-index: 500;
    bottom: 15px;
    gap: 10px;
}

.socials_inner{
    position: relative;
    height: fit-content;
    width: 100%;
    flex-basis: 100%;
    margin: 0 auto;
    display: inline-flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: -5px;
    flex-wrap: wrap;
    opacity: 0.8;
    align-items: center;
    justify-content: space-between;
    //padding-left: 30px;
    //border: 1px solid red;
}

.socials_item{
    height: 40px;
    width: 40px;
    margin: 10px;
    margin-top: 0px;
    display: table;
    border-radius: 200px;
    border: 2px solid var(--primary_colour);
    color: var(--primary_colour);
    background-color: var(--primary_colour);
    transition: margin-top 0.25s ease-in-out;

    -webkit-transition: background-color 0.25s linear;
    -moz-transition: background-color 0.25s linear;
    -o-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
}

.socials_item:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1.0);

    -webkit-transition: background-color 0.25s linear;
    -moz-transition: background-color 0.25s linear;
    -o-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
}

.socials_item_icon{
    height: 45%;
    width: 45%;
    margin: 0 auto;
    filter: none;
    transition: filter 0.25s linear;
}

.socials_item:hover .socials_item_icon{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(95%) contrast(105%);
    transition: filter 0.25s linear;
}

.settings_div{
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    opacity: 0.8;
    //border: 1px solid red;
}

.settings_button{
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    //border: 1px solid green;
}

.site_version{
    width: 100%;
    flex-basis: 100%;
    text-align: center;
    position: relative;
    font-family: sf_light;
    font-size: 10px;
    color: var(--secondary_colour);
    margin: 0 auto;
    margin-bottom: 5px;
    margin-top: 10px;
    //border: 1px solid red;
}

.middle_div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
    justify-content: flex-end;
    //border: 1px solid red;
}

@media only screen and (max-width: 750px) {
    .middle_div{
        display: none;
    }
}

.middle_div_item{
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    font-family: sf_regular;
    font-size: 15px;
    //color: var(--primary_colour);
    color: white;
    opacity: 0.6;
    transition: opacity 0.25s ease-in-out;
    //border: 1px solid red;
}

.middle_div_item:hover{
    opacity: 1.0;
    cursor: pointer;
}

.vertical_align{
    vertical-align: middle;
    display: table-cell;
    height: 100%;
    z-index: -10;
    width: 75px;
}

.home_div{
    position: relative;
    height: 100%;
    width: fit-content;
    max-height: var(--navbar_height);
    text-align: center;
    margin-left: 20px;
    display: table;
    //border: 1px solid blue;
}

.access_div{
    position: relative;
    width: 200px;
    height: var(--navbar_height);
    text-align: center;
    display: flex;
    margin-left: auto;
    //border: 1px solid blue;
}

@media only screen and (max-width: 750px) {
    .access_div{
        width: 175px;
    }
}

.access_inner_div{
    position: relative;
    width: fit-content;
    height: var(--navbar_height);
    text-align: center;
    display: table;
    margin-left: auto;
    margin-right: 20px;
}

.access_text{
    
    font-family: sf_semibold;
    font-size: 13px;
    //color: var(--primary_colour);
    color: white;
    transition: color 0.3s;
}

@media only screen and (max-width: 750px) {
    .access_text{
        font-size: 12px;
    }
}

.access_button{

    box-sizing: border-box;
    border-radius: 20px;
    //background: var(--dark_blue);
    background: transparent;
    //border: 2px solid var(--primary_colour);
    border: 2px solid white;
    height: 40px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    justify-content: center; // horizontal align
    display: flex; // vertical align
    align-items: center; // vertical align
    cursor: pointer;
    transition: background ease 0.25s;
}

@media only screen and (max-width: 750px) {
    .access_button{
        height: 35px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.access_button:hover{

    //background: var(--primary_colour);
    background: white;
    transition: background ease 0.25s;
}

.access_button:hover .access_text{
    //color: white;
    color: rgba(0, 0, 0, 1.0);
}

.desktop_inner{
    height: var(--navbar_height);
    width: 100%;
    max-width: var(--max_width);
    margin: 0 auto !important;
    float: none !important; 
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 19;
    //border: 1px solid red;
}