.desktop_root_upper{
    width: 100vw;
    padding-top: 65px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    //border: 1px solid green;
}

.desktop_root{
    height: fit-content;
    margin-top: 15px;
    width: 100%;
    flex-basis: 100%;
    display: table;
    //background-color: #525D69;
    //border: 1px solid red;
    //background: linear-gradient(to right, #f3f3f3, #f3f3f3);
}

.desktop_root_inner{
    position: relative;
    max-width: var(--max_width);
    width: 100%;
    margin: 0 auto;
    //border: 1px solid red;
}

.desktop_root_main_inner{
    position: relative;
    max-width: var(--max_width);
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    //border: 1px solid magenta;
}

.vertical_align{

    vertical-align: middle;
    display: table-cell;
    align-items: center;
    height: 100%;
    width: fit-content;
    //border: 2px solid green;
}

.vertical_align_super{

    vertical-align: middle;
    display: table-cell;
    height: fit-content;
    width: fit-content;
    //border: 1px solid green;
}

.left_block{
    width: fit-content;
    display: table;
    flex-basis: 0;
    flex-grow: 1;
    padding-top: 10px;
    //border: 1px solid green;
}

.left_block::after{
    height: 100%;
}

.middle_block{
    width: fit-content;
    height: 100%;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    flex-basis: 0;
    flex-grow: 2;
    min-width: 200px;
    //border: 1px solid green;
}

.right_div{
    height: fit-content;
    width: fit-content;
    display: table;
    padding-top: 10px;
    flex-basis: 0;
    flex-grow: 2;
    padding-right: 10px;
    //border: 1px solid green;
}

@media only screen and (max-width: 600px) {
    .left_block{
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .middle_block{
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .right_div{
        flex-basis: 100%;
        padding-right: 0px;
    }
}

.left_block_inner{
    width: 100%;
    height: fit-content;
    display: inline-block;
    margin: 0 auto;
    position: relative;
    //padding-left: 30px;
    //border: 1px solid green;
}

.logo_div{
    max-width: var(--max_width);
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    flex-basis: 100%;
    height: fit-content;
    //border: 1px solid green;
}

.logo{
    height: 110px;
    position: relative;
    margin: 0 auto;
    //border: 1px solid red;
}

.logo:hover{
    cursor: pointer;
}

.main_title{
    width: fit-content;
    height: fit-content;
    font-family: sf_bold;
    font-size: 30px;
    color: var(--primary_colour);
    opacity: 1.0;
    //transition: opacity 0.25s ease-in-out;
}

.main_title:hover{
    cursor: pointer;
    opacity: 1.0;
    //transition: opacity 0.25s ease-in-out;
}

.top{
    width: 100%;
    text-align: center;
    //border: 1px solid green;
}

.version{
    font-family: sf_thin;
    font-size: 12px;
    color: var(--secondary_colour);
    opacity: 0.75;
    top: 0;
    padding-left: 5px;
    //border: 1px solid green;
}

.description{
    font-family: sf_light;
    font-size: 13px;
    color: var(--primary_colour);
    margin-top: 20px;
}

.bottom_div{
    margin-top: 10px;
}

.settings_div{
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    //padding-right: 25px;
    //border: 1px solid green;
}

.right_div{
    height: fit-content;
    width: fit-content;
    display: table;
    padding-top: 10px;
    flex-basis: 0;
    flex-grow: 2;
    //border: 1px solid green;
}

.vertical_align_right{

    vertical-align: middle;
    display: table-cell;
    height: fit-content;
    width: fit-content;
    //border: 1px solid red;
}

.inner_div{
    height: 100%;
    width: fit-content;
    display: table;
    margin: 0 auto;
    border-radius: 8px;
    //border: 1px solid red;
}

.block_title{
    font-family: sf_semibold;
    font-size: 17px;
    margin-bottom: 20px;
    color: var(--primary_colour);
    opacity: 0.9;
    text-align: center;
    padding-top: 10px;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.contacts_line{
    display: flex;
    justify-content: flex-start;
    height: fit-content;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 10px;
    margin-bottom: 15px;
    //border: 1px solid green;
}

.contacts_title{
    width: 80px;
    font-family: sf_semibold;
    font-size: 15px;
    color: var(--primary_colour);
    //border: 1px solid green;
}

.contacts_separator{
    font-family: sf_light;
    font-size: 15px;
    color: var(--tertiary_colour);
    margin-right: 10px;
}

.contacts_text{
    position: relative;
    font-family: sf_light;
    font-size: 14px;
    width: 93%;
    text-align: justify;
    color: var(--secondary_colour);
    opacity: 1.0;
    //border: 1px solid black;
}

.contacts_text grey{
    position: relative;
    font-family: sf_light;
    font-style: italic;
    font-size: 14px;
    color: var(--tertiary_colour);
    opacity: 1.0;
}

.bottom_div{
    bottom: 10;
    position: relative;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    //border: 1px solid green;
}

.horizontal_line{
    display: block;
    max-width: var(--max_width);
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0;
}

.bread_crumbs_div{
    flex-grow: 0.5;
    flex-basis: 0;
    opacity: 1.0;
    //border: 1px solid magenta;
}

.legal_text_div{
    max-width: var(--max_width);
    margin: 0 auto;
    display: flex;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    //border: 1px solid green;
}

.main_legal_text{

    flex-grow: 0.5;
    flex-basis: 0;
    font-family: sf_light;
    text-align: center;
    width: fit-content;
    font-size: 13px;
    margin: 0 auto;
    opacity: 0.65;
    color: var(--secondary_colour);
    //border: 1px solid black;
}

.additional_legal_tex_div{
    flex-grow: 0.5;
    flex-basis: 0;
    text-align: right;
    display: flex;
    flex-direction: row;
    //text-decoration: underline;
    right: 0;
    float: right;
    margin-left: auto;
    //border: 1px solid green;
}

.additional_legal_text{
    float: right;
    word-wrap: break-word;
    width: fit-content;
    right: 0;
    margin-left: auto;
    text-align: center;
    font-family: sf_light;
    font-size: 13px;
    opacity: 0.65;
    transition: opacity 0.25s ease;
    color: var(--secondary_colour);
    //border: 1px solid green;
}

.additional_legal_text:hover{
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 0.25s ease;
}

.copyright_text{
    font-family: sf_regular;
    font-size: 13px;
    opacity: 1.0;
    margin-top: 18px;
    padding-left: 40px;
    color: var(--secondary_colour);
}