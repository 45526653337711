.vertical_align{

    vertical-align: middle;
    display: table-cell;
    align-items: center;
    height: 100%;
    width: fit-content;
    //border: 2px solid green;
}

.items_div{
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: inline-flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    //padding-left: 30px;
    //border: 1px solid red;
}

.item_div{
    height: 40px;
    width: 40px;
    margin: 10px;
    margin-top: 0px;
    display: table;
    border-radius: 200px;
    border: 2px solid var(--primary_colour);
    color: var(--primary_colour);
    background-color: var(--primary_colour);
    transition: margin-top 0.25s ease-in-out;

    -webkit-transition: background-color 0.25s linear;
    -moz-transition: background-color 0.25s linear;
    -o-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
}

.item_div:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1.0);

    -webkit-transition: background-color 0.25s linear;
    -moz-transition: background-color 0.25s linear;
    -o-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
}

.item_div_icon{
    height: 45%;
    width: 45%;
    margin: 0 auto;
    filter: none;
    transition: filter 0.25s linear;
}

.item_div:hover .item_div_icon{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(95%) contrast(105%);
    transition: filter 0.25s linear;
}