.desktop_root{

    width: 100%;
    opacity: 0.75;
    margin: 0 auto;
    display: flex;
    height: fit-content;
    //border: 1px solid black;
}

.vertical_align{

    vertical-align: middle;
    display: table-cell;
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.bread_crumb_div{
    display: table;
}

.bread_crumb_text{
    width: fit-content;
    font-family: sf_light;
    font-size: 13px;
    opacity: 0.75;
    transition: opacity 0.25s ease;
    color: var(--secondary_colour);
    padding-right: 1px;
}

.bread_crumb_text:hover{
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 0.25s ease;
}

.arrow_image{
    height: 7px;
    width: 7px;
    position: relative;
}

.bread_crumb_arrow_div{
    display: table;
    height: fit-content;
    margin-left: 10px;
    margin-right: 10px;
    width: fit-content;
    //border: 1px solid green;
}